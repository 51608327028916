import { BE_LINKS, PROD_LINK } from "./constants"

export const isBrowser = () => typeof window !== "undefined"

export const getBaseUrl = () => {
    if (!isBrowser()) return
    let baseUrl = ""

    const originUrl = window.location.origin
    if (originUrl === PROD_LINK) {
        baseUrl = BE_LINKS.PROD
    } else {
        baseUrl = BE_LINKS.DEV
    }

    return baseUrl
}

export const isProdLocation = () => typeof window !== "undefined" && window.location.origin === PROD_LINK

export const sendEventAnalytics = (name, event_name, data) =>
    typeof window !== "undefined" && window?.gtag && window?.gtag(name, event_name, data)
