export const BOOKING = true

export const PROD_LINK = "https://www.maliblueyachts.com"

export const STRIPE_PB_KEYS = {
    DEV: "pk_test_51L1wM9ETELrBeDAysQGtfFbS7p7gAcCqkYaUl96vTWoEMTLGRvAbTYGkKtmmCmvI4JAl3v8dUxKq9hNEGxMZ2sm200nQ1UiMzG",
    PROD: "pk_live_51L1wM9ETELrBeDAyUpbsZk3FEbqV9ozIGhFQbLyBQfBxN1b8KL8p3cqD3xMaIAMqDiT2jB2xerbECq9nfzU3kvvw00iX6MpV65"
}

export const BE_LINKS = {
    DEV: "https://maliblueyachts-back-end-development.azurewebsites.net/",
    PROD: "https://maliblueyachts-back-end-production.azurewebsites.net/"
}

export const bookingSteps = [
    {
        name: "your details",
        urlName: "details"
    },
    {
        name: "select yacht",
        urlName: "yacht"
    },
    {
        name: "select date",
        urlName: "date"
    },
    {
        name: "select route",
        urlName: "route"
    },
    {
        name: "select extras",
        urlName: "extras"
    },
    {
        name: "dietary",
        urlName: "dietary"
    },
    {
        name: "payment",
        urlName: "payment"
    }
]
